import React from 'react'
import { useToggle } from '../../../../hooks/useToggle'
import { CircularProgress } from '@mui/material'
import { ApiInterceptor } from '../../../Api/ApiInterceptor'
import { Link } from '../../../Link'

export interface RefreshP24PaymentProps {
  orderId: string
  onChange: () => void
}

export function RefreshP24Payment({
  orderId,
  onChange
}: RefreshP24PaymentProps) {
  const [isLoading, startLoading, stopLoading] = useToggle()

  async function refreshPayment() {
    startLoading()

    try {
      // TODO: show status in a tooltip
      await ApiInterceptor.post(
        `/api/membership-admin/payment-order/${orderId}`,
        {},
        {
          headers: {
            'Content-Type':
              'application/vnd.membership.v2.refreshP24Payment+json'
          }
        }
      )
      onChange()
    } finally {
      stopLoading()
    }
  }

  if (isLoading) {
    return <CircularProgress size={16} />
  }

  return <Link onClick={refreshPayment}>Synchonizuj z P24</Link>
}

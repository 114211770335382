import { Gender, MemberFeePeriodType, MembershipPurpose } from './types'

export const PeriodLabel = {
  [MemberFeePeriodType.YEAR]: 'roczny',
  [MemberFeePeriodType.QUARTER]: 'kwartalny',
  [MemberFeePeriodType.HALF_YEAR]: 'półroczny',
  [MemberFeePeriodType.MONTH]: 'miesięczny'
}

export const PurposeLabel = {
  [MembershipPurpose.SPORT]: 'sport',
  [MembershipPurpose.COLLECTION]: 'kolekcja',
  [MembershipPurpose.BOTH]: 'sport+kolekcja'
}

export const GenderLabel = {
  [Gender.MALE]: 'M',
  [Gender.FEMALE]: 'K'
}

export enum PayUCreateOrderResponseStatus {
  SUCCESS = 'SUCCESS',
  WARNING_CONTINUE_REDIRECT = 'WARNING_CONTINUE_REDIRECT',
  WARNING_CONTINUE_3DS = 'WARNING_CONTINUE_3DS',
  WARNING_CONTINUE_CVV = 'WARNING_CONTINUE_CVV',
  ERROR_SYNTAX = 'ERROR_SYNTAX',
  ERROR_VALUE_INVALID = 'ERROR_VALUE_INVALID',
  ERROR_VALUE_MISSING = 'ERROR_VALUE_MISSING',
  ERROR_ORDER_NOT_UNIQUE = 'ERROR_ORDER_NOT_UNIQUE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST',
  DATA_NOT_FOUND = 'DATA_NOT_FOUND',
  TIMEOUT = 'TIMEOUT',
  BUSINESS_ERROR = 'BUSINESS_ERROR',
  ERROR_INTERNAL = 'ERROR_INTERNAL',
  GENERAL_ERROR = 'GENERAL_ERROR',
  WARNING = 'WARNING',
  SERVICE_NOT_AVAILABL = 'SERVICE_NOT_AVAILABLE'
}

export enum GatewayType {
  MANUAL = 'MANUAL',
  PAY_U = 'PAY_U',
  P24 = 'P24'
}

export enum PayUOrderStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  REFUNDED = 'REFUNDED'
}

export enum P24OrderStatus {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
  RETURNED = 'RETURNED'
}

export enum PaymentFeeType {
  ENTRY_FEE = 'ENTRY_FEE',
  MEMBER_FEE = 'MEMBER_FEE',
  LICENSE_FEE = 'LICENSE_FEE'
}

export enum ManualPaymentReason {
  CORRECTION = 'CORRECTION',
  SYNC = 'SYNC',
  EXEMPT = 'EXEMPT',
  OTHER = 'OTHER'
}

export enum ManualPaymentStatus {
  ACTIVE = 'ACTIVE',
  OBSOLETE = 'OBSOLETE'
}

export enum PaymentOrderType {
  MEMBERSHIP = 'MEMBERSHIP',
  BOOKING = 'BOOKING'
}
